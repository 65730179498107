<template>
  <!-- 系统 - 新增支付配置 -->
  <div class="box-card" style="text-align: left;">
    <!-- 主要内容 -->
    <el-form :model="queryForm" :rules="queryFormRules" ref="queryFormRef" label-width="200px" class="demo-ruleForm">
      <el-form-item v-if="title == '新增'" label="集团：" prop="group_id"
        :rules="{ required: true, message: '请选择集团', trigger: 'blur' }" class="ruleFormSelect">
        <el-select popper-class="my-select" v-model="queryForm.group_id" placeholder="请选择集团">
          <el-option v-for="(item, index) in groupList" :key="index" :label="item.group_name"
            :value="item._id"></el-option>
        </el-select>
      </el-form-item>
      <div v-else>
        <div class="title">停车场</div>
        <el-divider></el-divider>
        <el-form-item label="集团：" style="margin-bottom: 0px;margin-left:15px;">
          {{ group_name }}
        </el-form-item>
        <el-form-item label="停车场：" style="margin-left:15px;">
          {{ parking_name }}
        </el-form-item>
      </div>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="微信" name="wx">
          <el-row :gutter="70">
            <el-col :span="12">
              <el-form-item label="应用密钥secrect：" prop="wx_configsecret">
                <el-input v-model="queryForm.wx_configsecret"
                  @input="(e) => (queryForm.wx_configsecret = validSpacing(e))" placeholder="请输入secrect"
                  disabled></el-input>
              </el-form-item>
              <el-form-item label="appid：" prop="wx_configappid">
                <el-input v-model="queryForm.wx_configappid" @input="(e) => (queryForm.wx_configappid = validSpacing(e))"
                  placeholder="请输入appid" disabled></el-input>
              </el-form-item>
              <el-form-item label="小程序appid：" prop="wx_configapplets_appid">
                <el-input v-model="queryForm.wx_configapplets_appid"
                  @input="(e) => (queryForm.wx_configapplets_appid = validSpacing(e))" placeholder="请输入小程序appid"
                  disabled></el-input>
              </el-form-item>
              <el-form-item label="支付回调地址域名：" prop="wx_configdomain">
                <el-input v-model="queryForm.wx_configdomain"
                  @input="(e) => (queryForm.wx_configdomain = validSpacing(e))" placeholder="请输入paykey"
                  disabled></el-input>
              </el-form-item>
              <el-form-item label="子商户号：" prop="wx_configsub_mchId">
                <el-input v-model="queryForm.wx_configsub_mchId"
                  @input="(e) => (queryForm.wx_configsub_mchId = validSpacing(e))" placeholder="请输入子商户号"></el-input>
              </el-form-item>
              <!-- <el-form-item label="有效期至：" prop="wx_configexpiry_date">
                  <el-date-picker :editable="false" :clearable="false" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd" v-model="queryForm.wx_configexpiry_date" type="date" placeholder="请选择有效期">
                  </el-date-picker>
                </el-form-item> -->
            </el-col>
            <el-col :span="12">
              <el-form-item label="商户号mchid：" prop="wx_configmchId">
                <el-input v-model="queryForm.wx_configmchId" @input="(e) => (queryForm.wx_configmchId = validSpacing(e))"
                  placeholder="请输入mchid" disabled></el-input>
              </el-form-item>
              <el-form-item label="API密钥key：" prop="wx_configkey">
                <el-input v-model="queryForm.wx_configkey" @input="(e) => (queryForm.wx_configkey = validSpacing(e))"
                  placeholder="请输入key" disabled></el-input>
              </el-form-item>
              <el-form-item label="APIv3密钥：" prop="wx_configapiv3key">
                <el-input v-model="queryForm.wx_configapiv3key"
                  @input="(e) => (queryForm.wx_configapiv3key = validSpacing(e))" placeholder="请输入paykey"
                  disabled></el-input>
              </el-form-item>
              <el-form-item label="API证书地址：" prop="wx_configcertificate">
                <el-input v-model="queryForm.wx_configcertificate"
                  @input="(e) => (queryForm.wx_configcertificate = validSpacing(e))" placeholder="请输入paykey"
                  disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="支付宝" name="zfb">
          <el-row :gutter="70">
            <el-col :span="12">
              <el-form-item label="应用id：" prop="zfb_configappid">
                <el-input v-model="queryForm.zfb_configappid"
                  @input="(e) => (queryForm.zfb_configappid = validSpacing(e))" placeholder="请输入支付宝应用id"></el-input>
              </el-form-item>
              <el-form-item label="应用私钥：" prop="zfb_configappprivatekey">
                <el-input v-model="queryForm.zfb_configappprivatekey"
                  @input="(e) => (queryForm.zfb_configappprivatekey = validSpacing(e))" placeholder="请输入应用私钥"></el-input>
              </el-form-item>
              <el-form-item label="域名：" prop="zfb_configdomain">
                <el-input v-model="queryForm.zfb_configdomain"
                  @input="(e) => (queryForm.zfb_configdomain = validSpacing(e))" placeholder="请输入域名"></el-input>
              </el-form-item>
              <el-form-item label="有效期至：" prop="zfb_configexpiry_date">
                <el-date-picker :editable="false" :clearable="false" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd"
                  v-model="queryForm.zfb_configexpiry_date" type="date" placeholder="请选择有效期">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="应用公钥证书：" prop="zfb_configapppubliccert">
                <el-input v-model="queryForm.zfb_configapppubliccert"
                  @input="(e) => (queryForm.zfb_configapppubliccert = validSpacing(e))"
                  placeholder="请输入应用公钥证书"></el-input>
              </el-form-item>
              <el-form-item label="支付宝公钥证书：" prop="zfb_configalipaypubliccert">
                <el-input v-model="queryForm.zfb_configalipaypubliccert"
                  @input="(e) => (queryForm.zfb_configalipaypubliccert = validSpacing(e))"
                  placeholder="请输入支付宝公钥证书"></el-input>
              </el-form-item>
              <el-form-item label="支付宝根证书：" prop="zfb_configalipayrootcert">
                <el-input v-model="queryForm.zfb_configalipayrootcert"
                  @input="(e) => (queryForm.zfb_configalipayrootcert = validSpacing(e))"
                  placeholder="请输入支付宝根证书"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>
      <div class="title">打印小票</div>
      <el-divider></el-divider>
      <el-form-item label="收费单位：" prop="charging_unit">
        <el-input v-model="queryForm.charging_unit" @input="(e) => (queryForm.charging_unit = validSpacing(e))"
          placeholder="请输入收费单位"></el-input>
      </el-form-item>
      <el-form-item label="监督机关：" prop="supervisory_authority">
        <el-input v-model="queryForm.supervisory_authority"
          @input="(e) => (queryForm.supervisory_authority = validSpacing(e))" placeholder="请输入监督机关"></el-input>
      </el-form-item>
      <el-form-item label="温馨提示：" prop="reminder" style="margin-bottom:0px">
        <el-input type="textarea" rows="5" v-model="queryForm.reminder" placeholder="请输入温馨提示"
          @blur="queryForm.reminder = ((queryForm.reminder.replace(/\r\n|\n|\r/g, '<br/>')).replace(/\s/g, '')).replace(/<br\/>/g, '\n')"></el-input>
      </el-form-item>
    </el-form>
    <!-- <lbButton type="goBack" icon="back" class="checkButton2" @click="defaultForm">返 回</lbButton> -->
    <lbButton type="default" fill icon="confirm" class="checkButton" @click="submitAddForm" v-preventReClick>保 存
    </lbButton>
  </div>
</template>

<script>
import systemapi from '@/api/systemapi'
import { mapMutations, mapGetters } from 'vuex'
export default {
  props: ['id'],
  data () {
    return {
      // 集团列表
      groupList: [],
      group_name: '',
      parking_name: '',
      // 表单数据
      queryForm: {
        // _id: '',
        group_id: '',
        wx_configsecret: '',
        wx_configappid: '',
        wx_configapplets_appid: '',
        wx_configmchId: '',
        wx_configkey: '',
        wx_configapiv3key: '',
        wx_configcertificate: '',
        wx_configdomain: '',
        wx_configsub_mchId: '',
        wx_configexpiry_date: '',
        zfb_configappid: '',
        zfb_configappprivatekey: '',
        zfb_configapppubliccert: '',
        zfb_configalipaypubliccert: '',
        zfb_configalipayrootcert: '',
        zfb_configdomain: '',
        zfb_configexpiry_date: '',
        charging_unit: '',
        supervisory_authority: '',
        reminder: ''
      },
      activeName: 'wx',
      // 验证规则
      queryFormRules: {
        group_id: [{ required: true, message: '请选择集团', trigger: 'blur' }],
        wx_configsecret: [{ required: true, message: '请输入secrect', trigger: 'blur' }],
        wx_configappid: [{ required: true, message: '请输入appid', trigger: 'blur' }],
        wx_configapplets_appid: [{ required: true, message: '请输入小程序appid', trigger: 'blur' }],
        wx_configmchId: [{ required: true, message: '请输入mchid', trigger: 'blur' }],
        wx_configkey: [{ required: true, message: '请输入key', trigger: 'blur' }],
        wx_configapiv3key: [{ required: true, message: '请输入apiv3key', trigger: 'blur' }],
        wx_configcertificate: [{ required: true, message: '请输入certificate', trigger: 'blur' }],
        wx_configdomain: [{ required: true, message: '请输入domain', trigger: 'blur' }],
        wx_configsub_mchId: [{ required: true, message: '请输入子商户号', trigger: 'blur' }],
        wx_configexpiry_date: [{ required: true, message: '请选择有效期', trigger: 'blur' }],
        zfb_configappid: [{ required: true, message: '请输入支付宝应用id', trigger: 'blur' }],
        zfb_configappprivatekey: [{ required: true, message: '请输入应用私钥', trigger: 'blur' }],
        zfb_configapppubliccert: [{ required: true, message: '请输入应用公钥证书', trigger: 'blur' }],
        zfb_configalipaypubliccert: [{ required: true, message: '请输入支付宝公钥证书', trigger: 'blur' }],
        zfb_configalipayrootcert: [{ required: true, message: '请输入支付宝根证书', trigger: 'blur' }],
        zfb_configdomain: [{ required: true, message: '请输入域名', trigger: 'blur' }],
        zfb_configexpiry_date: [{ required: true, message: '请选择有效期', trigger: 'blur' }],
        charging_unit: [{ required: true, message: '请输入收费单位', trigger: 'blur' }],
        supervisory_authority: [{ required: true, message: '请输入监督机关', trigger: 'blur' }],
        reminder: [{ required: true, message: '请输入温馨提示', trigger: 'blur' }]
      }
      // pickerOptions: {
      //   disabledDate: time => {
      //     const endDateVal = new Date()
      //     if (endDateVal) {
      //       return time.getTime() < new Date(endDateVal).getTime();
      //     }
      //   }
      // },
    }
  },
  created () {
    this.fnGetGroupList()
    // console.log(this.$route.query.id);
    if (this.id) {
      this.title = '修改'
      this.fngetDetailsList()
    } else {
      this.title = '新增'
      // console.log(this.title);
      this.fngetNewPayDetail()
    }
  },
  mounted () {
  },
  computed: {
  },
  methods: {
    ...mapMutations(['setQueryStatus']),
    // 返回
    defaultForm () {
      this.setQueryStatus(2)
      this.$emit('closeDialog', false)
    },
    // 获取未配置支付配置的集团下拉列接口
    async fnGetGroupList () {
      const res = await systemapi.getPayGroupList()
      // console.log('-- 获取集团列表--', res);
      if (res && res.Code === 200) {
        this.groupList = res.Data
      }
    },
    // 获取支付配置详情
    async fngetDetailsList () {
      const res = await systemapi.getPayDetail({
        _id: this.id
      })
      // console.log('---获取支付配置详情---', res);
      if (res && res.Code === 200) {
        this.queryForm.group_id = res.Data.group_id
        if (res.Data.wx_config) {
          this.queryForm.wx_configsecret = res.Data.wx_config.secret
          this.queryForm.wx_configappid = res.Data.wx_config.appid
          this.queryForm.wx_configapplets_appid = res.Data.wx_config.applets_appid
          this.queryForm.wx_configmchId = res.Data.wx_config.mchId
          this.queryForm.wx_configkey = res.Data.wx_config.key
          this.queryForm.wx_configapiv3key = res.Data.wx_config.apiv3key
          this.queryForm.wx_configcertificate = res.Data.wx_config.certificate
          this.queryForm.wx_configdomain = res.Data.wx_config.domain
          // this.queryForm.wx_configexpiry_date = res.Data.wx_config.expiry_date
          this.queryForm.wx_configsub_mchId = res.Data.wx_config.sub_mchId
        }
        if (res.Data.zfb_config) {
          this.queryForm.zfb_configappid = res.Data.zfb_config.appid
          this.queryForm.zfb_configappprivatekey = res.Data.zfb_config.appprivatekey
          this.queryForm.zfb_configapppubliccert = res.Data.zfb_config.apppubliccert
          this.queryForm.zfb_configalipaypubliccert = res.Data.zfb_config.alipaypubliccert
          this.queryForm.zfb_configalipayrootcert = res.Data.zfb_config.alipayrootcert
          this.queryForm.zfb_configdomain = res.Data.zfb_config.domain
          this.queryForm.zfb_configexpiry_date = res.Data.zfb_config.expiry_date
        }
        this.group_name = res.Data.group_name
        this.parking_name = res.Data.parking_name
        this.queryForm.charging_unit = res.Data.charging_unit
        this.queryForm.supervisory_authority = res.Data.supervisory_authority
        this.queryForm.reminder = res.Data.reminder
      }
    },
    // 获取新增支付配置详情
    async fngetNewPayDetail () {
      const res = await systemapi.getNewPayDetail()
      if (res.Code === 200) {
        this.queryForm.wx_configsecret = res.Data.secret
        this.queryForm.wx_configappid = res.Data.appid
        this.queryForm.wx_configapplets_appid = res.Data.applets_appid
        this.queryForm.wx_configmchId = res.Data.mchId
        this.queryForm.wx_configkey = res.Data.key
        this.queryForm.wx_configapiv3key = res.Data.apiv3key
        this.queryForm.wx_configcertificate = res.Data.certificate
        this.queryForm.wx_configdomain = res.Data.domain
        // this.queryForm.wx_configexpiry_date = res.Data.expiry_date
        this.queryForm.wx_configsub_mchId = res.Data.sub_mchId
      }
    },
    handleClick (tab, event) {
      // console.log('handleClick---tab----', tab);
      // console.log('handleClick---event----', event);
    },
    //   新增或修改
    submitAddForm () {
      // console.log(this.queryForm);
      // 对整个表单进行校验
      var propsArr = []
      if (!this.id) {
        propsArr.push('group_id')
      }
      propsArr.push('charging_unit', 'supervisory_authority', 'reminder')
      if (this.queryForm.wx_configsecret !== '' ||
        this.queryForm.wx_configappid !== '' ||
        this.queryForm.wx_configapplets_appid !== '' ||
        this.queryForm.wx_configmchId !== '' ||
        this.queryForm.wx_configkey !== '' ||
        this.queryForm.wx_configapiv3key !== '' ||
        this.queryForm.wx_configcertificate !== '' ||
        this.queryForm.wx_configdomain !== '' ||
        this.queryForm.wx_configsub_mchId !== '') {
        propsArr.push('wx_configsecret',
          'wx_configapplets_appid',
          'wx_configappid',
          'wx_configmchId',
          'wx_configkey',
          'wx_configapiv3key',
          'wx_configcertificate',
          'wx_configdomain',
          'wx_configsub_mchId')
        // console.log('propsArr---', propsArr);
      }
      if (
        this.queryForm.zfb_configappid !== '' ||
        this.queryForm.zfb_configappprivatekey !== '' ||
        this.queryForm.zfb_configapppubliccert !== '' ||
        this.queryForm.zfb_configalipaypubliccert !== '' ||
        this.queryForm.zfb_configalipayrootcert !== '' ||
        this.queryForm.zfb_configdomain !== '' ||
        this.queryForm.zfb_configexpiry_date !== ''
      ) {
        propsArr.push('zfb_configappid',
          'zfb_configappprivatekey',
          'zfb_configapppubliccert',
          'zfb_configalipaypubliccert',
          'zfb_configalipayrootcert',
          'zfb_configdomain',
          'zfb_configexpiry_date')
      }
      var newErr = 0
      this.$refs.queryFormRef.validateField(propsArr, err => {
        // console.log(err);
        if (!err) {
          newErr++
        } else {
          return false
        }
      })
      if (propsArr.length === newErr) {
        var obj = {}
        obj.group_id = this.queryForm.group_id
        obj.charging_unit = this.queryForm.charging_unit
        obj.supervisory_authority = this.queryForm.supervisory_authority
        obj.reminder = this.queryForm.reminder
        if (this.queryForm.wx_configsecret !== '') {
          // obj.wx_config = this.queryForm.wx_config
          obj.wx_config = {
            secret: this.queryForm.wx_configsecret,
            appid: this.queryForm.wx_configappid,
            applets_appid: this.queryForm.wx_configapplets_appid,
            mchId: this.queryForm.wx_configmchId,
            key: this.queryForm.wx_configkey,
            apiv3key: this.queryForm.wx_configapiv3key,
            certificate: this.queryForm.wx_configcertificate,
            domain: this.queryForm.wx_configdomain,
            // expiry_date: this.queryForm.wx_configexpiry_date,
            sub_mchId: this.queryForm.wx_configsub_mchId
          }
        }
        if (this.queryForm.zfb_configappid !== '') {
          obj.zfb_config = {
            appid: this.queryForm.zfb_configappid,
            appprivatekey: this.queryForm.zfb_configappprivatekey,
            apppubliccert: this.queryForm.zfb_configapppubliccert,
            alipaypubliccert: this.queryForm.zfb_configalipaypubliccert,
            alipayrootcert: this.queryForm.zfb_configalipayrootcert,
            domain: this.queryForm.zfb_configdomain,
            expiry_date: this.queryForm.zfb_configexpiry_date
          }
        }
        if (this.id) {
          obj._id = this.id
          this.fnupdatePayConfig(obj)
        } else {
          // this.title = "新增"
          this.fnaddPayConfig(obj)
        }
      }
      // this.$refs.queryFormRef.validate((valid) => {
      //   // 成功
      //   if (valid) {
      //     var obj = {}
      //     obj = this.queryForm
      //     // console.log(this.queryForm);
      //   } else {
      //     return false;
      //   }
      // });
    },
    // 新增支付配置
    async fnaddPayConfig (obj) {
      const res = await systemapi.addPayConfig(obj)
      // console.log('--- 新增支付配置---', res);
      if (res && res.Code === 200) {
        this.$emit('closeDialog', false)
      }
    },
    // 修改支付配置
    async fnupdatePayConfig (obj) {
      const res = await systemapi.updatePayConfig(obj)
      // console.log('--- 修改支付配置---', res);
      if (res && res.Code === 200) {
        this.defaultForm()
      }
    }
  }
}
</script>

<style scoped lang="less">
.el-select {
  width: 40%;
}

.title {
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  color: #000;
}

.el-tabs {
  /deep/ .el-tabs__item {
    font-size: 18px !important;
    font-weight: 700 !important;
    border-right: none !important;
  }

  /deep/ .el-tabs__nav-wrap::after {
    height: 1px;
    background-color: #f2f2f2;
  }

  /deep/ .el-tabs__active-bar {
    height: 1px;
  }
}

.demo-ruleForm {
  max-height: 650px;
  overflow-y: auto;

  .ruleFormSelect {
    width: 44.5%;

    .el-input {
      width: 100%;
    }

    .el-select {
      width: 100%;
    }

    /deep/ .el-input__inner {
      width: 100% !important;
    }

  }

  /deep/ .el-input__inner {
    width: 90% !important;
  }

  /deep/ .el-textarea__inner {
    width: 90% !important;
  }
}

.box-card {
  position: relative;
  padding-bottom: 30px;

  .checkButton {
    position: absolute;
    right: 10px;
  }

  .checkButton2 {
    position: absolute;
    right: 110px;
  }
}</style>
