<template>
  <!-- 系统 - 支付配置 -->
  <div class="box-card" style="text-align: left;margin-top: 20px;">
    <!-- 查询表单 -->
    <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
      @submit.native.prevent>
      <el-form-item label="集团：">
        <el-select popper-class="my-select" v-model="queryForm.group_id" placeholder="请选择集团" filterable clearable
          default-first-option @change="onSubmit">
          <el-option v-for="(item, index) in groupList" :key="index" :label="item.group_name"
            :value="item._id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
      </el-form-item>
    </el-form>
    <le-card title="支付配置">
      <template slot="heard-right">
        <div class="right">
          <lbButton type="err" icon="shanchu1" @click="batchDel">删除</lbButton>
          <lbButton icon="xinzeng" @click="handelPaymentConfig('')">新增</lbButton>
        </div>
      </template>
      <!-- 主要内容 -->
      <div>
        <!-- 表格 -->
        <!-- 使用插件生产表格 -->
        <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @selection-change="selectionChange"
          @size-change="sizeChange" @current-change="currentChange">
          <template slot-scope="{row}" slot="create_time">
            {{ $moment(row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
          </template>
          <template slot-scope="{row}" slot="menu">
            <lbButton type="setting" icon="shebeipeizhi" hint="支付配置" @click="handelPaymentConfig(row._id)"></lbButton>
          </template>
        </avue-crud> -->
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" border stripe
          @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="50" align="center" header-align="center"></el-table-column>
          <el-table-column prop="group_name" label="集团"></el-table-column>
          <el-table-column prop="parking_count" label="停车场个数"></el-table-column>
          <el-table-column prop="zfb" label="支付宝"></el-table-column>
          <el-table-column prop="etc" label="ETC"></el-table-column>
          <el-table-column label="操作时间">
            <template slot-scope="scope">
              {{ $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <lbButton type="setting" icon="shebeipeizhi" hint="支付配置"
                @click="handelPaymentConfig(scope.row._id)"></lbButton>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </le-card>
    <!-- 新增编辑支付配置 -->
    <lebo-dialog :title="dialogPaymentConfigId ? '编辑支付配置' : '新增支付配置'" :isShow="showPaymentConfig" width="85%"
      @close="showPaymentConfig = false" footerSlot>
      <addPaymentConfig v-if="showPaymentConfig" :id="dialogPaymentConfigId" @closeDialog="closePaymentConfig">
      </addPaymentConfig>
    </lebo-dialog>
  </div>
</template>

<script>
import systemapi from '@/api/systemapi'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import addPaymentConfig from './components/addPaymentConfig/index.vue'
export default {
  components: { addPaymentConfig },
  data () {
    return {
      // 查询表单
      queryForm: {
        group_id: '',
        PageSize: 10,
        PageIndex: 1
      },
      total: 0,
      // 表格数据
      tableData: [],
      //   集团列表
      groupList: [],
      multipleSelection: [],
      dialogPaymentConfigId: '',
      showPaymentConfig: false,
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        selection: true,
        selectionFixed: false, // 解除选择框冻结
        // searchBtn:false,
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '集团',
            prop: 'group_name'
          }, {
            label: '停车场个数',
            prop: 'parking_count'
          }, {
            label: '支付宝',
            prop: 'zfb'
          }, {
            label: 'ETC',
            prop: 'etc'
          }, {
            label: '操作时间',
            prop: 'create_time',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  created () {
    if (this.queryStatus === 2) {
      this.queryForm = this.deepClone(this.queryObj)
    }
    this.fnGetGroupList()
    this.fnGetPaymentConfigList()
  },
  mounted () {
    window.addEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  beforeRouteLeave (to, from, next) {
    this.setQueryObj(this.queryForm)
    this.setQueryStatus(1)
    next()
  },
  destroyed () {
    window.removeEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  computed: {
    ...mapState(['parkingList', 'queryObj', 'queryStatus']),
    ...mapGetters(['getOptions', 'getWayName'])
  },
  methods: {
    ...mapActions(['requestPorkingList', 'requestFindList']),
    ...mapMutations(['setQueryObj', 'setQueryStatus']),
    beforeunloadFn (e) {
      this.setQueryStatus(1)
    },
    // 获取支付配置列表
    async fnGetPaymentConfigList () {
      const res = await systemapi.getPaymentConfigList(this.queryForm)
      // console.log('-- 获取支付配置列表--', res);
      if (res && res.Code === 200) {
        this.total = res.Data.TotalCount
        this.tableData = res.Data.DataList
      } else {
        this.total = 0
        this.tableData = []
      }
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    // 获取集团列表
    async fnGetGroupList () {
      const res = await systemapi.getGroupList({
        group_name: '',
        PageSize: 0,
        PageIndex: 0
      })
      // console.log('-- 获取集团列表--', res);
      if (res && res.Code === 200) {
        this.groupList = res.Data.DataList
      }
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.fnGetPaymentConfigList()
    },
    // 每页条数改变
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`);
      this.queryForm.PageSize = val
      this.fnGetPaymentConfigList()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.fnGetPaymentConfigList()
    // },
    // 页码改变
    handleCurrentChange (val) {
      this.queryForm.PageIndex = val
      this.fnGetPaymentConfigList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0

      // console.log(`当前页: ${val}`);
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.fnGetPaymentConfigList()
    // },
    // 当选择项发生变化时会触发该事件
    handleSelectionChange (val) {
      // console.log(val);
      this.multipleSelection = val
    },
    // // 选中的数据
    // selectionChange (array) {
    //   this.deleteArray = array
    // },
    // 批量删除
    batchDel () {
      if (this.multipleSelection.length > 0) {
        this.$confirm('此操作将永久删除选中的支付配置, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          var obj = {}
          var arr = []
          for (var i = 0; i < this.multipleSelection.length; i++) {
            arr.push(this.multipleSelection[i]._id)
            if (arr.length === this.multipleSelection.length) {
              obj._ids = arr
              this.fndelPayConfig(obj)
            }
          }
        }).catch(() => {
          this.$msg.info('已取消删除')
        })
      } else {
        this.$msg.warning('请选择要删除的支付配置！')
      }
    },
    async fndelPayConfig (obj) {
      const res = await systemapi.delPayConfig(obj)
      if (res && res.Code === 200) {
        this.$msg.success(res.Message)
      }
      this.fnGetPaymentConfigList()
    },
    // 新增编辑支付配置
    handelPaymentConfig (id) {
      this.dialogPaymentConfigId = id
      this.showPaymentConfig = true
    },
    closePaymentConfig () {
      this.showPaymentConfig = false
      this.fnGetPaymentConfigList()
    }
  }
}
</script>

<style scoped lang="less"></style>
